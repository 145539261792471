/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import EmailFormMenTopView from './EmailFormMenTopView'
import EmailFormBottomMenView from './EmailFormBottomMenView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60d1f13be0e7f0983d5c1a71").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class SteadyForMenView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/SteadyForMenController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = SteadyForMenView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60e6e298be6f52211481488d'
    htmlEl.dataset['wfSite'] = '60d1f13be0e7f0983d5c1a71'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = SteadyForMenView.Controller !== SteadyForMenView ? transformProxies(this.props.children) : {
      'email-form-men-top': [],
      'email-form-bottom-men': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/steady-landing-page.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <header id="hero-overlay" className="af-class-hero-overlay-1 af-class-wf-section">
              <div className="af-class-div-block-5"><img src="images/60ce9eaedee7a63257e6550a_logo-1.png" loading="lazy" width={214} sizes="214px" srcSet="images/60ce9eaedee7a63257e6550a_logo-1-p-500.png 500w, images/60ce9eaedee7a63257e6550a_logo-1-p-800.png 800w, images/60ce9eaedee7a63257e6550a_logo-1.png 1096w" alt="Steady logo" className="af-class-image-13" /></div>
              <div className="af-class-centered-container w-container">
                <h1 className="af-class-heading-9">Let's break down some walls.</h1>
                <p className="af-class-paragraph-4">We unblock your path to building a stronger, <br />more resilient, you.</p>
                {map(proxies['email-form-men-top'], props => <EmailFormMenTopView.Controller {...props}>{props.children}</EmailFormMenTopView.Controller>)}
              </div>
            </header>
            <div id="Feature-1" className="af-class-section-7 af-class-wf-section">
              <div className="af-class-columns-3 af-class-mobile w-row">
                <div className="af-class-column-5 w-col w-col-6 w-col-small-small-stack">
                  <h1 className="af-class-heading-10">No judgement.<br />No shame.<br /><span className="af-class-text-span-6">Just freedom.</span></h1>
                </div>
                <div className="af-class-column-6 w-col w-col-6 w-col-small-small-stack">
                  <div className="af-class-text-block-3">Our mental coaches help you <span className="af-class-text-span-7">overcome</span> your obstacles like stress, anxiety or depression on your own time.</div>
                </div>
              </div><img src="images/Male-Landing-Page.png" loading="lazy" sizes="(max-width: 5760px) 100vw, 5760px" srcSet="images/Male-Landing-Page-p-500.png 500w, images/Male-Landing-Page-p-800.png 800w, images/Male-Landing-Page-p-1080.png 1080w, images/Male-Landing-Page-p-1600.png 1600w, images/Male-Landing-Page-p-2000.png 2000w, images/Male-Landing-Page-p-2600.png 2600w, images/Male-Landing-Page-p-3200.png 3200w, images/Male-Landing-Page.png 5760w" alt className="af-class-image-8" />
            </div>
            <div className="af-class-container-5 w-container"><img src="images/Mobile-1.png" loading="lazy" sizes="100vw" srcSet="images/iPhone-XR-p-500.png 500w, images/iPhone-XR-p-800.png 800w, images/iPhone-XR-p-1080.png 1080w, images/iPhone-XR-p-1600.png 1600w, images/iPhone-XR-p-2000.png 2000w, images/iPhone-XR-p-2600.png 2600w, images/iPhone-XR-p-3200.png 3200w, images/Mobile-1.png 3449w" alt className="af-class-image-10" /><img src="images/Group-224.png" loading="lazy" srcSet="images/Group-224-p-500.png 500w, images/Group-224-p-800.png 800w, images/Group-224-p-1080.png 1080w, images/Group-224-p-1600.png 1600w, images/Group-224.png 2424w" sizes="100vw" alt className="af-class-image-14" /></div>
            <header id="hero-overlay" className="af-class-section-11 af-class-wf-section">
              <div className="af-class-centered-container w-container">
                <h1 className="af-class-heading-11">Building a strong mind, <br />builds a stronger you.</h1>
              </div>
            </header>
            <div id="Feature-1" className="af-class-section-7 af-class-wf-section">
              <div className="af-class-columns-2-copy w-row">
                <div className="af-class-column-2-copy w-col w-col-6"><img src="images/Group-175.svg" loading="lazy" height width={58} alt="user icon" className="af-class-image" />
                  <h1 className="af-class-heading-5">Understand yourself better</h1>
                  <p className="af-class-lower-column-copy">Complete virtual psychological assessments to better understand yourself and make more intentional decisions.</p>
                </div>
                <div className="af-class-column-top w-col w-col-6"><img src="images/Group-173-2.png" loading="lazy" width={510} sizes="(max-width: 479px) 74vw, (max-width: 767px) 40vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-173-2-p-500.png 500w, images/Group-173-2-p-800.png 800w, images/Group-173-2-p-1080.png 1080w, images/Group-173-2-p-1600.png 1600w, images/Group-173-2.png 1896w" alt className="af-class-image-5" /></div>
              </div>
            </div>
            <div id="features" className="af-class-section-5 af-class-wf-section">
              <div className="af-class-columns-2 w-row">
                <div className="af-class-column-2 w-col w-col-6"><img src="images/Group-173.png" loading="lazy" width={510} sizes="(max-width: 479px) 78vw, (max-width: 767px) 41vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-173-p-500.png 500w, images/Group-173-p-800.png 800w, images/Group-173-p-1080.png 1080w, images/Group-173-p-1600.png 1600w, images/Group-173.png 1896w" alt="check in preview" className="af-class-image-4" /></div>
                <div className="af-class-column-top-copy w-col w-col-6"><img src="images/Group-168.png" loading="lazy" width={88} height alt="pen writing icon" className="af-class-image" />
                  <h1 className="af-class-heading-5-copy">Build your self-awareness.</h1>
                  <p className="af-class-lower-column">Daily check-ins for you and your therapist to help you through your journey to a more intentional life.</p>
                </div>
              </div>
            </div>
            <div className="af-class-section-7-copy af-class-wf-section">
              <div className="af-class-columns-2-copy w-row">
                <div className="af-class-column-2-copy w-col w-col-6"><img src="images/Group-34.png" loading="lazy" height width={94} alt="stats icon" className="af-class-image" />
                  <h1 className="af-class-heading-5">Create lasting habits with goal tracking</h1>
                  <p className="af-class-lower-column-copy">Set personal goals and see how you have progressed throughout your Steady journey.</p>
                </div>
                <div className="af-class-column-top w-col w-col-6"><img src="images/Group-174.png" loading="lazy" width={510} sizes="(max-width: 479px) 74vw, (max-width: 767px) 40vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-174-p-500.png 500w, images/Group-174-p-800.png 800w, images/Group-174-p-1080.png 1080w, images/Group-174-p-1600.png 1600w, images/Group-174.png 1896w" alt="preview of progress page" className="af-class-image-5" /></div>
              </div>
            </div><img src="images/Desktop---4_1.png" loading="lazy" alt className="af-class-image-17" /><img src="images/X---1.png" loading="lazy" sizes="100vw" srcSet="images/X---1-p-500.png 500w, images/X---1.png 4884w" alt className="af-class-image-16" />
            <div className="af-class-section-4 af-class-wf-section">
              {map(proxies['email-form-bottom-men'], props => <EmailFormBottomMenView.Controller {...props}>{props.children}</EmailFormBottomMenView.Controller>)}
            </div>
            <footer id="footer" className="af-class-footer af-class-wf-section">
              <div className="w-container">
                <div className="af-class-footer-flex-container">
                  <div>
                    <h2 className="af-class-footer-heading">Support</h2>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="mailto:hello@heysteady.com" className="af-class-footer-link">hello@heysteady.com</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="af-class-footer-heading">Follow us</h2>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="http://instagram.com/heysteadysg" target="_blank" className="af-class-footer-link">@heysteadysg</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="http://bit.ly/steady-privacy" target="_blank" className="af-class-footer-link">Privacy Policy</a>
                        <a href="http://bit.ly/steady-terms" target="_blank" className="af-class-footer-link">Terms of use</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="af-class-div-block-4">Copyright © 2021 Steady. All rights reserved.</div>
              </div>
            </footer>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default SteadyForMenView

/* eslint-enable */