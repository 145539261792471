import React, { useState } from "react";
import { qs } from "url-parse";

import { IndexView } from "../views/index";

import EmailChange from "../pages/emailChange/EmailChange";

import './controller.css';


const IndexController = (props) => {

  const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true })
  
  const emailChangeToken = queryString.email_change_token

  if(queryString && emailChangeToken){
    return <EmailChange emailChangeToken={emailChangeToken}/>
  }else{
    return(
      <IndexView >
        <email-form-top {...props}/>
        <email-form-bottom {...props}/>
      </IndexView>
    )
  }
}

export default IndexController;