import React, { useState } from "react";

import { EmailFormTopView } from "../views/index";

import { notifiyEmailSubmission } from "../services/emailSubmission";
import { insertToEarlySubmissions } from "../services/earlyAccessSubmissions";

import './controller.css';

const EmailFormTopController = (props) => {

    const [email, setEmail] = useState(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isError, setError] = useState(false);

    const onChangeEmail = (e) => {
        setEmail(e.target.value.trim());
    }

    const onSubmit = async () => {
        setSubmitting(true);
        const regex = new RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
        const validEmail = regex.test(`#${email}`);
        if(email && email.length > 0 && validEmail){
            const error = await insertToEarlySubmissions(email);
            if(!error){
                await notifiyEmailSubmission(email)
                window.location.assign("https://heysteady.typeform.com/to/l7c0reLV");
            }else{
                setError(true);
                setSubmitting(false);
            }
        }else{
            setSubmitting(false);
        }
        
    }

    return (
        <EmailFormTopView>
          <email-top onChange={onChangeEmail} />
          <submit-top style={{backgroundColor: !isSubmitting ? '#0997ff' : 'grey', color: "white",  }}  onClick={isSubmitting ? null : onSubmit}/>
          { isError ? <form-fail-top className="display-error"/> : <form-fail-top  style={{display: "none" }} /> }
        </EmailFormTopView>
      );
}

export default EmailFormTopController;