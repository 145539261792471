import axios from "axios";

import { ApiURL } from "../utils/constants";

const notifiyEmailSubmission = (email) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`${ApiURL}/hooks/email-submission`, {
        email: email,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { notifiyEmailSubmission };
