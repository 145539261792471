import React, { useState } from "react";

import { SteadyForMenView } from "../views/index";



import './controller.css';


const IndexController = (props) => {

  return(
    <SteadyForMenView>
      <email-form-men-top {...props} />
      <email-form-bottom-men {...props} />
    </SteadyForMenView>
  )

}

export default IndexController;