import React, { useState, useCallback } from "react";
import { Input, Button } from "antd";
import axios from "axios";
import {ApiURL} from '../../../utils/constants';
import "../../../utils/layout.css";
import "../../../utils/constants.css";
import "./forgetPassword.css";

const ForgetPassword = (props) => {
  const publicDir = process.env.PUBLIC_URL;
  const logoImagePath = publicDir + "/assets/images/logo.png";
  const waveImagePath = publicDir + "/assets/images/wave.png";

  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const changeNewPassword = useCallback((e) => {
    setNewPassword(e.target.value.trim());
  }, []);

  const changeConfirmNewPassword = useCallback((e) => {
    setConfirmNewPassword(e.target.value.trim());
  }, []);

  const onReset = useCallback(async () => {

    if (newPassword === confirmNewPassword) {
      const validPassword = RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
      );
      const isPasswordValid = validPassword.test(newPassword.trim());
      if (isPasswordValid && newPassword.length > 8) {
        setIsResetting(true);

        axios.post(`${ApiURL}/accounts/reset-password`,{
          "new_password": newPassword.trim(), 
          "access_token": props.accessToken
        })
        .then((res)=>{
          console.log(res)
          if(res.data.success){
            setIsResetting(false);
            setIsSuccessful(true);
          }else{
            setIsResetting(false);
          }
        })
        .catch(e=>{
          console.log(e)
          setIsResetting(false);
          setErrorMsg("error has occured");
        })
      } else {
        setErrorMsg(
          "Make sure your password has the following conditions as stated above."
        );
      }
    } else {
      setErrorMsg(
        "Passwords do not match. Please ensure that you have typed the correct password."
      );
    }
  }, [newPassword, confirmNewPassword]);

  return (
    <div className="fullscreen flex-column-start-center">
      <img className="logo-image" src={logoImagePath} />
      {!isSuccessful ? (
        <>
          <div className="description flex-row-start-center primary-font">
            <p>
              Passwords must have at least 1 uppercase letter, 1 lowercase
              letter, 1 special character and 1 number.
              <br/><br/>
              Character count must be more than or equal to 8
            </p>
          </div>
          <div className="description flex-row-start-center primary-font" style={{color:"red"}}>
            {errorMsg}
          </div>

          <Input
            type="password"
            className="textInput"
            value={newPassword}
            placeholder="New Password"
            onChange={changeNewPassword}
          />
          <Input
            type="password"
            className="textInput"
            value={confirmNewPassword}
            placeholder="Confirm New Password"
            onChange={changeConfirmNewPassword}
          />

          <Button
            className="button"
            type="primary"
            shape="round"
            size="large"
            onClick={onReset}
            disabled={isResetting}
          >
            Reset
          </Button>
        </>
      ) : (
        <div>success</div>
      )}

      <img className="wave-image" src={waveImagePath} />
    </div>
  );
};

export default ForgetPassword;
