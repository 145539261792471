import axios from "axios";
import {ApiURL} from "../utils/constants"

const insertToEarlySubmissions = async (email) => {
    axios.post(`${ApiURL}/early-access-submissions/add-email`,{
        "email": email, 
      })
      .then((res)=>{
        return true
      })
      .catch(e=>{
        return false
      })
}

export {
    insertToEarlySubmissions
};