import { BrowserRouter, Route, Switch } from "react-router-dom";
import Auth from './pages/auth/Auth.js';
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy.js";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse"
import EmailFormController from './controllers/IndexController.js';
import SteadyForMenController from './controllers/SteadyForMenController.js';
import Payment from './pages/payment/Payment.js';

import './utils/webflow.css';

function App() {
  return (
      <BrowserRouter>
      <Switch>
          <Route exact path="/auth" component={Auth} />
          <Route exact path="/" component={EmailFormController} />
          <Route exact path="/for-men" component={SteadyForMenController} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-of-use" component={TermsOfUse} />
          <Route exact path="/payment" component={Payment} />
          
      </Switch>
    </BrowserRouter>
  );
}

export default App;
