/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import EmailFormTopView from './EmailFormTopView'
import EmailFormBottomView from './EmailFormBottomView'

const scripts = [
  { loading: Promise.resolve("var request=new XMLHttpRequest;request.open(\"GET\",\"https://blog.heysteady.com/ghost/api/v4/content/posts/?key=37418b549fc2db2ae12053cb77\",!0),request.onload=function(){var t=JSON.parse(this.response).posts;200<=request.status&&request.status<400&&0<t.length?t.forEach(t=>{const e=document.createElement(\"a\");e.setAttribute(\"href\",t.url),e.setAttribute(\"style\",\`text-decoration:none; cursor:pointer; color:inherit;\`);const s=document.createElement(\"div\");s.setAttribute(\"class\",\"blog-article-card\"),s.setAttribute(\"style\",\`display: flex;flex-direction: column;margin-bottom: 20px;margin-right: 20px;10px: ;width: 40vw;height: 40vh;background-color: white;border-radius: 8px;-webkit-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.2);-moz-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.2);box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.2);padding-bottom: 20px;\`);const o=document.createElement(\"img\");o.setAttribute(\"class\",\"blog-article-image\"),o.setAttribute(\"style\",\"width: 100%; height: 50%; height: 60%; object-fit: cover; border-radius: 8px 8px 0px 0px;\"),o.src=t.feature_image;const r=document.createElement(\"span\");r.setAttribute(\"class\",\"blog-article-title\"),r.setAttribute(\"style\",\"font-family: Avenirnext, sans-serif; font-weight: bold; padding: 10px 10px;\"),r.textContent=t.title;const i=document.createElement(\"span\");i.setAttribute(\"class\",\"blog-article-description\"),i.setAttribute(\"style\",\"font-family: Avenirnext, sans-serif; padding: 0px 10px; margin-bottom: 10px; text-overflow: ellipsis;\"),i.textContent=\`${t.custom_excerpt}...\`,s.appendChild(o),s.appendChild(r),s.appendChild(i),e.appendChild(s),document.getElementById(\"blog-article-carousel\").appendChild(e)}):console.log(\"error\")},request.send();"), isAsync: false },
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=60d1f13be0e7f0983d5c1a71").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '60d1f13ce0e7f05e5b5c1a76'
    htmlEl.dataset['wfSite'] = '60d1f13be0e7f0983d5c1a71'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      'email-form-top': [],
      'email-form-bottom': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/steady-landing-page.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-section-8 af-class-wf-section">
              <a href="index.html" aria-current="page" className="af-class-link-block w-inline-block w--current"><img src="images/60ce9eaedee7a63257e6550a_logo-1.png" loading="lazy" width={154} sizes="154px" srcSet="images/60ce9eaedee7a63257e6550a_logo-1-p-500.png 500w, images/60ce9eaedee7a63257e6550a_logo-1-p-800.png 800w, images/60ce9eaedee7a63257e6550a_logo-1.png 1096w" alt="Steady logo" className="af-class-image-6" /></a>
              <div data-collapse="tiny" data-animation="default" data-duration={400} role="banner" className="af-class-navbar w-nav">
                <div className="af-class-nav-link w-container">
                  <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                    <a href="#" className="af-class-nav-link-2 w-nav-link">For Therapists</a>
                    <a href="#how-it-works" className="af-class-nav-link w-nav-link">How does it work</a>
                    <a href="#Feature-1" className="af-class-nav-link w-nav-link">Features</a>
                    <a href="#pricing" className="af-class-nav-link w-nav-link">Pricing</a>
                    <a href="#hero" className="af-class-nav-link-3 w-nav-link">Get&nbsp;Early Access</a>
                  </nav>
                  <div className="af-class-menu-button w-nav-button">
                    <div className="af-class-icon w-icon-nav-menu" />
                  </div>
                </div>
              </div>
            </div>
            <div id="hero" className="af-class-hero-sec af-class-wf-section">
              <div className="af-class-div-block">
                <h1 className="af-class-heading">Affordable mental health care for <br />‍<span className="af-class-text-span">Gen-Zs &amp;&nbsp;Millennials.</span></h1>
                <div className="af-class-text-block">Mobile app based counselling for<br />depression, anxiety, relationships, stress and more</div>
                {map(proxies['email-form-top'], props => <EmailFormTopView.Controller {...props}>{props.children}</EmailFormTopView.Controller>)}
              </div>
              <div className="af-class-div-block-11">
                <h2 className="af-class-heading-14">Can't afford $150/hour therapy sessions?</h2><img src="images/Website-Pricing-Mobile.png" loading="lazy" sizes="100vw" srcSet="images/Website-Pricing-Mobile-p-500.png 500w, images/Website-Pricing-Mobile-p-800.png 800w, images/Website-Pricing-Mobile-p-1080.png 1080w, images/Website-Pricing-Mobile-p-1600.png 1600w, images/Website-Pricing-Mobile-p-2000.png 2000w, images/Website-Pricing-Mobile-p-2600.png 2600w, images/Website-Pricing-Mobile-p-3200.png 3200w, images/Website-Pricing-Mobile.png 3256w" alt className="af-class-image-24" /><img src="images/Website-Pricing-Desktop.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, (max-width: 1919px) 60vw, 50vw" srcSet="images/Website-Pricing-Desktop-p-500.png 500w, images/Website-Pricing-Desktop-p-800.png 800w, images/Website-Pricing-Desktop-p-1080.png 1080w, images/Website-Pricing-Desktop-p-1600.png 1600w, images/Website-Pricing-Desktop-p-2000.png 2000w, images/Website-Pricing-Desktop-p-2600.png 2600w, images/Website-Pricing-Desktop-p-3200.png 3200w, images/Website-Pricing-Desktop.png 5192w" alt className="af-class-image-23" /><img src="images/Group-263horizontal2.png" loading="lazy" sizes="(max-width: 767px) 100vw, (max-width: 991px) 80vw, 922px" srcSet="images/Group-263horizontal2-p-500.png 500w, images/Group-263horizontal2.png 922w" alt className="af-class-image-22" /><img src="images/Group-267vertical.png" loading="lazy" alt className="af-class-image-21" />
              </div>
            </div>
            <div id="how-it-works" className="af-class-section-2 af-class-wf-section">
              <div>
                <h1 className="af-class-header-2">A listening ear <span className="af-class-text-span-2">when</span> you need it</h1>
              </div><img src="images/Desktop-3.png" loading="lazy" sizes="(max-width: 5760px) 100vw, 5760px" srcSet="images/Desktop-3-p-800.png 800w, images/Desktop-3-p-1080.png 1080w, images/Desktop-3-p-1600.png 1600w, images/Desktop-3-p-2000.png 2000w, images/Desktop-3-p-2600.png 2600w, images/Desktop-3-p-3200.png 3200w, images/Desktop-3.png 5760w" alt className="af-class-image-8" />
              <div className="af-class-container-5 w-container"><img src="images/Mobile-1.png" loading="lazy" sizes="100vw" srcSet="images/iPhone-XR-p-500.png 500w, images/iPhone-XR-p-800.png 800w, images/iPhone-XR-p-1080.png 1080w, images/iPhone-XR-p-1600.png 1600w, images/iPhone-XR-p-2000.png 2000w, images/iPhone-XR-p-2600.png 2600w, images/iPhone-XR-p-3200.png 3200w, images/Mobile-1.png 3449w" alt className="af-class-image-10" /><img src="images/Mobile-2.png" loading="lazy" srcSet="images/Mobile-2-p-500.png 500w, images/Mobile-2-p-800.png 800w, images/Mobile-2-p-1080.png 1080w, images/Mobile-2-p-1600.png 1600w, images/Mobile-2.png 2324w" sizes="100vw" alt /></div>
            </div>
            <div className="af-class-section-13 af-class-wf-section">
              <div className="af-class-container-6 w-container">
                <h2 className="af-class-heading-13">Meet our counselors</h2>
              </div>
              <div className="af-class-columns-4 w-row">
                <div className="af-class-column-7 w-col w-col-6 w-col-stack w-col-small-small-stack">
                  <div className="af-class-div-block-9" />
                </div>
                <div className="w-col w-col-6 w-col-stack w-col-small-small-stack">
                  <ul role="list" className="af-class-list w-list-unstyled">
                    <li className="af-class-list-item-2 af-class-list-item-icon">Has worked or currently works in a counselling-related position</li>
                    <li className="af-class-list-item-4 af-class-list-item-icon">Must abide by the Singapore Association for Counselling <a href="https://sacsingapore.org/membership/code-of-ethics/" target="_blank" className="af-class-link">code of ethics</a>
                    </li>
                    <li className="af-class-list-item af-class-list-item-icon"> Hold an undergraduate / postgraduate degree in Counselling, Psychology, Social Work or its equivalent</li>
                    <li className="af-class-list-item af-class-list-item-icon">Depression, Anxiety,&nbsp;Relationships, LGBTQIA+ and more...</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="af-class-section-3 af-class-wf-section">
              <h1 className="af-class-heading-6">Busy with work or school?</h1>
              <h1 className="af-class-header-2">Get the <span className="af-class-text-span-4">support</span> you<span className="af-class-text-span-5"> </span>need<span className="af-class-text-span-5"> throughout your day.</span></h1><img src="images/SLICE-2-2.png" loading="lazy" sizes="(max-width: 5741px) 100vw, 5741px" srcSet="images/SLICE-2-2-p-800.png 800w, images/SLICE-2-2-p-1080.png 1080w, images/SLICE-2-2-p-1600.png 1600w, images/SLICE-2-2-p-2000.png 2000w, images/SLICE-2-2-p-2600.png 2600w, images/SLICE-2-2-p-3200.png 3200w, images/SLICE-2-2.png 5741w" alt="daily timeline view" className="af-class-image-2" />
            </div>
            <div id="Feature-1" className="af-class-section-7 af-class-wf-section">
              <div className="af-class-columns-2-copy w-row">
                <div className="af-class-column-2-copy w-col w-col-6"><img src="images/Group-175.svg" loading="lazy" height width={58} alt="user icon" className="af-class-image" />
                  <h1 className="af-class-heading-5">Learn more about yourself with psychological tests</h1>
                  <p className="af-class-lower-column-copy">Complete virtual psychological assessments to better understand yourself and make more intentional decisions.</p>
                </div>
                <div className="af-class-column-top w-col w-col-6"><img src="images/Group-173-2.png" loading="lazy" width={510} sizes="(max-width: 479px) 74vw, (max-width: 767px) 40vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-173-2-p-500.png 500w, images/Group-173-2-p-800.png 800w, images/Group-173-2-p-1080.png 1080w, images/Group-173-2-p-1600.png 1600w, images/Group-173-2.png 1896w" alt className="af-class-image-5" /></div>
              </div>
            </div>
            <div id="features" className="af-class-section-5 af-class-wf-section">
              <div className="af-class-columns-2 w-row">
                <div className="af-class-column-2 w-col w-col-6"><img src="images/Group-173.png" loading="lazy" width={510} sizes="(max-width: 479px) 75vw, (max-width: 767px) 41vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-173-p-500.png 500w, images/Group-173-p-800.png 800w, images/Group-173-p-1080.png 1080w, images/Group-173-p-1600.png 1600w, images/Group-173.png 1896w" alt="check in preview" className="af-class-image-4" /></div>
                <div className="af-class-column-top-copy w-col w-col-6"><img src="images/Group-168.png" loading="lazy" width={88} height alt="pen writing icon" className="af-class-image" />
                  <h1 className="af-class-heading-5-copy">Journal your way to better self-awareness</h1>
                  <p className="af-class-lower-column">Daily check-ins for you and your therapist to help you through your journey to a more intentional life.</p>
                </div>
              </div>
            </div>
            <div className="af-class-section-7-copy af-class-wf-section">
              <div className="af-class-columns-2-copy w-row">
                <div className="af-class-column-2-copy w-col w-col-6"><img src="images/Group-34.png" loading="lazy" height width={94} alt="stats icon" className="af-class-image" />
                  <h1 className="af-class-heading-5">Tracking your small wins every step of the way</h1>
                  <p className="af-class-lower-column-copy">Set personal goals and see how you have progressed throughout your Steady journey.</p>
                </div>
                <div className="af-class-column-top w-col w-col-6"><img src="images/Group-174.png" loading="lazy" width={510} sizes="(max-width: 479px) 74vw, (max-width: 767px) 40vw, (max-width: 991px) 43vw, (max-width: 1919px) 48vw, 510px" srcSet="images/Group-174-p-500.png 500w, images/Group-174-p-800.png 800w, images/Group-174-p-1080.png 1080w, images/Group-174-p-1600.png 1600w, images/Group-174.png 1896w" alt="preview of progress page" className="af-class-image-5" /></div>
              </div>
            </div>
            <div id="pricing" className="af-class-wf-section">
              <div className="af-class-container-3 w-container"><img src="images/Desktop---4.png" loading="eager" alt /></div>
            </div>
            <div className="af-class-container-4 w-container"><img src="images/X---1.png" loading="eager" width={2442} sizes="(max-width: 767px) 100vw, (max-width: 991px) 728px, 100vw" srcSet="images/X---1-p-500.png 500w, images/X---1.png 4884w" alt className="af-class-image-9" /></div>
            <div id="blog-article-section" className="af-class-section-12 af-class-wf-section">
              <h2 className="af-class-heading-12">Recommended reading</h2>
              <div id="blog-article-carousel" className="af-class-div-block-8">
                <div className="af-class-html-embed w-embed w-script">
                </div>
              </div>
            </div>
            <div className="af-class-section-4 af-class-wf-section">
              <div className="af-class-div-block-2">
                <h2 className="af-class-heading-copy">Get the support you need to enjoy your life.</h2>
                {map(proxies['email-form-bottom'], props => <EmailFormBottomView.Controller {...props}>{props.children}</EmailFormBottomView.Controller>)}
              </div>
            </div>
            <footer id="footer" className="af-class-footer af-class-wf-section">
              <div className="w-container">
                <div className="af-class-footer-flex-container">
                  <div>
                    <h2 className="af-class-footer-heading">Support</h2>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="mailto:hello@heysteady.com" className="af-class-footer-link">hello@heysteady.com</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="af-class-footer-heading">Follow us</h2>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="http://instagram.com/heysteadysg" target="_blank" className="af-class-footer-link">@heysteadysg</a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul role="list" className="w-list-unstyled">
                      <li>
                        <a href="http://bit.ly/steady-privacy" target="_blank" className="af-class-footer-link">Privacy Policy</a>
                        <a href="http://bit.ly/steady-terms" target="_blank" className="af-class-footer-link">Terms of use</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="af-class-div-block-4">Copyright © 2021 Steady. All rights reserved.</div>
              </div>
            </footer>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */